.nav-bar__asset-notif:hover .support_tools {
    display: block;
}

.support_tools {
    display: none;
    z-index: 999;
    position: absolute;
    top: 30px;
    right: 0;
    left: -190px;
    min-height: 285px;
    max-height: 700px;
    width: 300px;
    background: var(--primary-pale-color);
    margin: 8px;
    border-radius: 10px;
    overflow: scroll;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    box-shadow: 2px 2px #666;
}

.support_tools.support_note {
    display: none;
    left: -210px;
    padding: 4px;
    z-index: 999;
    position: absolute;
    top: 30px;
    right: 0;
    min-height: 274px;
    max-height: 700px;
    width: 400px;
    background: var(--primary-pale-color); 
    margin: 8px;
    border-radius: 10px;
    overflow: scroll;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    box-shadow: 2px 2px #666;
}

.support_tools::before {
    /* arrow*/
    z-index: 999;
    content: "";
    cursor: default;
    border-width: 18px 27px;
    border-style: solid;
    border-color: transparent transparent var(--primary-blur-color) transparent;
    position: absolute;
    right: 0px;
    top: -35px;
}

.support_tools::after {
    /* road support*/
    z-index: 999;
    content: "";
    display: block;
    position: absolute;
    top: -18px;
    right: 0;
    width: 100%;
    height: 19px;
    cursor: default;
    background: red;
}

.support_tools__title {
    text-align: center;
    color: white;
    align-items: center;
}

.support_tools__list {
    text-emphasis: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.support_tools__item {
    padding: 4px 4px;
    margin: 4px 8px;
    background: white;
    border-radius: 10px;
}

.support_tools__list__item textarea {
    border: none;
}
.support_tools__item:hover {
    font-size: 1.5rem;
    box-shadow: 2px 2px red;
}

.support_tools__item .support_tools__item--title {
    font-weight: 600;
    border-bottom: 1px solid #666;
}

.support_tools__not_mes {
    text-align: center;
}

.support_tools__item-not_message {
    color: white;
    text-align: center;
}

.support_tools__item-img {
    width: 80%;
}

.support_tools__list__item {
    border: 1px solid #333;
    text-align: left;
    border-radius: 25px;
    background-color: orange;
    padding: 12px;
    margin: auto;
}

.support_tools__list__text {
    color: black;
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    padding-left: 24px;
}

.support_note-text {
    width: 97%;
}
.support_note-copy_icon {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    color: blue;
    margin: auto;
    padding-left: 12px;
}

@media print {
    @page {
        size: A4; /* DIN A4 standard, Europe */
        /* margin: 4px; */
      }

    html, body {
    /* width: 210mm; */
    /* height: 297mm; */
    /* height: 282mm; */
    /* font-size: 11px;
    background: #FFF;
    overflow: visible; */
    }
    
    body {
        /* padding-top: 15mm; */
        margin: 100;
        padding: 0;
        background: red;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }

}