.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-top: 10px
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
    height: 20px;
    width: 20px;
    min-width: 50px;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    height: 20px;
    width: 20px;
    min-width: 50px;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    font-size: 1.2rem;
}

.makeRoom-suggest-company-list {
    background-color: white;
    font-size: 1.2rem;
    position: absolute;
    left: 85px;
    width: 80%;
    text-align: left;
    z-index: 99;
    max-height: 200px;
    overflow: auto;
}

.makeRoom_company_detail {
    border: 1px solid; 
    background-color: #efefef; 
    width: 90%; 
    margin-left: 34px; 
    margin-top: 4px;
}

.makeRoom_children-company-clear_company_title {
    color: red ;
    font-size: 1.4rem ;
    margin-left: 100px
}

.makeRoom_children-company-clear_company_button {
    color: white; 
    width: 30px;
    padding: 2px;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
}

.memberShip_text1{
    text-align: left;
    font-size: 2rem;
    margin-left: 10px;
    padding-top: 5px;
  }
  .memberShip_text2{
    text-align: left;
    font-size: 1.5rem;
    margin-left: 10px;
    padding-top: 5px;
  }
  .memberShip_text3{
    text-align: left;
    font-size: 1.4rem;
    margin-left: 10px;
    padding-top: 5px;
  }
  .memberShip_logo{
    height: 100px;
    margin-left: 50px;
    aspect-ratio: 1;
  }
  .memberShip_id{
    font-size: 1.7rem;
    text-transform: uppercase;
    letter-spacing: 9px
  }
  .memberShip_container{
    border-radius: 10px;
    height: 230px
  }
  .rank-image{
    height: 60px
  }
  .rank-title{
    text-transform: uppercase;
    font-size: 1.5rem;
  }
  /* .rainbow-text {
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: inline-block;
  } */
/* 
  .rainbow-text {
    padding-left: 8px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    animation: rainbow 3s infinite;
    display: inline-block;

} */

.rainbow-text {
  font-family: Arial, sans-serif;
  font-weight: bold;
  animation: rainbow 3s infinite;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  display: inline-block;
}

.rainbow-bg {
  display: inline-block;
  padding: 10px 20px;
  font-size: 36px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  background-size: 600% 600%;
  animation: rainbow-bg 5s ease infinite;
  border-radius: 10px; /* Thêm bo góc */
}

@keyframes rainbow {
    0% { color: red; }
    20% { color: orange; }
    40% { color: yellow; }
    60% { color: green; }
    80% { color: blue; }
    100% { color: purple; }
}

@keyframes rainbow-bg {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}